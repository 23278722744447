// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'

import { InfoAndImage } from 'views/components'
import m5 from './img/m5.png'
import m52x from './img/m5@2x.png'
import m53x from './img/m5@3x.png'
import m5Mobile from './img/m5-mobile.png'
import m5Mobile2x from './img/m5-mobile@2x.png'
import m5Mobile3x from './img/m5-mobile@3x.png'
import { breakpoints, tablet } from 'shared/media-queries'

const getWordingWidth = screenWidth => {
	if (screenWidth <= breakpoints.mobile) {
		return '290px'
	} else if (screenWidth <= breakpoints.tablet) {
		return '630px'
	} else if (screenWidth <= breakpoints.laptop) {
		return '460px'
	}

	return '485px'
}

const TrackCompetition = ({ screenWidth }: { screenWidth: number }) => (
	<InfoAndImage
		screenWidth={screenWidth}
		image={
			screenWidth < breakpoints.tablet ? (
				<Img
					width="100%"
					src={m5Mobile}
					srcSet={`
						${m5Mobile2x} 2x,
						${m5Mobile3x} 3x,
					`}
					css={{
						[tablet]: { height: 'auto' },
					}}
				/>
			) : (
				<Img
					width="100%"
					src={m5}
					srcSet={`
						${m52x} 2x,
						${m53x} 3x,
					`}
					css={{
						[tablet]: { height: 'auto', width: '75%' },
					}}
				/>
			)
		}
		subtitle={
			<Div maxWidth={getWordingWidth(screenWidth)}>
				Track your competition and see how you stack up.
			</Div>
		}
		info="Input your competitors’ information and compare details like bid amounts and wins. Make informed decisions by tracking how your competitors bid."
		centered={screenWidth < breakpoints.laptop}
		wordingWidth={getWordingWidth(screenWidth)}
		spacerWidths={['120px', '25px']}
	/>
)

export default TrackCompetition
