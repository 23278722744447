// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'

import { InfoAndImage } from 'views/components'
import m3 from './img/m3.png'
import m32x from './img/m3@2x.png'
import m33x from './img/m3@3x.png'
import m3Mobile from './img/m3-mobile.png'
import m3Mobile2x from './img/m3-mobile@2x.png'
import m3Mobile3x from './img/m3-mobile@3x.png'
import { breakpoints, tablet } from 'shared/media-queries'

const getWordingWidth = screenWidth => {
	if (screenWidth <= breakpoints.mobile) {
		return '290px'
	} else if (screenWidth <= breakpoints.tablet) {
		return '600px'
	} else if (screenWidth <= breakpoints.laptop) {
		return '457px'
	}

	return '485px'
}

const SeeHow = ({ screenWidth }: { screenWidth: number }) => (
	<InfoAndImage
		screenWidth={screenWidth}
		image={
			screenWidth < breakpoints.tablet ? (
				<Img
					src={m3Mobile}
					srcSet={`
					${m3Mobile2x} 2x,
					${m3Mobile3x} 3x,
				`}
					css={{
						[tablet]: { height: 'auto' },
					}}
				/>
			) : (
				<Img
					src={m3}
					srcSet={`
					${m32x} 2x,
					${m33x} 3x,
				`}
					css={{
						[tablet]: { height: 'auto', width: '75%' },
					}}
				/>
			)
		}
		subtitle={
			<Div maxWidth={getWordingWidth(screenWidth)}>
				See how each estimator on your team is performing.
			</Div>
		}
		info="Keep your team accountable. See the number of bids submitted and won, contract values, hit rates and more. This means you can put your best estimators on the bids you want to win the most."
		centered={screenWidth <= breakpoints.tablet}
		wordingWidth={getWordingWidth(screenWidth)}
		spacerWidths={['120px', '20px']}
	/>
)

export default SeeHow
