// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'
import { breakpoints, mobile } from 'shared/media-queries'
import InfoGrid from './info-grid'

import m1 from './img/m1.png'
import m12x from './img/m1@2x.png'
import m13x from './img/m1@3x.png'
import m1Mobile from './img/m1-mobile.png'
import m1Mobile2x from './img/m1-mobile@2x.png'
import m1Mobile3x from './img/m1-mobile@3x.png'
import Heading from './heading'

import Award from './img/award.svg'
import Scale from './img/scale.svg'
import Mail from './img/mail.svg'
import List from './img/list.svg'

const sections = [
	{
		icon: <Scale />,
		description: 'Compare bids submitted, hit rates, contract values and more',
	},
	{
		icon: <Award />,
		description: 'See who you’re winning with and with whom you’re not',
	},
	{
		icon: <List />,
		description: `Prioritize your best GCs to be more efficient with your time`,
	},
	{
		icon: <Mail />,
		description: `Follow up with GCs with lower hit rates to find out how you can improve`,
	},
]

const EssentialGC = ({ screenWidth }: { screenWidth: number }) => (
	<Div>
		<Heading />
		<Div
			height="50px"
			css={{
				[mobile]: { height: '30px' },
			}}
		/>
		{screenWidth < breakpoints.tablet ? (
			<Img
				width="100%"
				src={m1Mobile}
				srcSet={`${m1Mobile2x} 2x, ${m1Mobile3x} 3x`}
			/>
		) : (
			<Img width="100%" src={m1} srcSet={`${m12x} 2x, ${m13x} 3x`} />
		)}
		<Div
			height="50px"
			css={{
				[mobile]: { height: '30px' },
			}}
		/>
		<InfoGrid sections={sections} />
	</Div>
)

export default EssentialGC
