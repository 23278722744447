// @flow
import * as React from 'react'
import { Img } from 'glamorous'

import { InfoAndImage } from 'views/components'
import m2 from './img/m2.png'
import m22x from './img/m2@2x.png'
import m23x from './img/m2@3x.png'
import m2Mobile from './img/m2-mobile.png'
import m2Mobile2x from './img/m2-mobile@2x.png'
import m2Mobile3x from './img/m2-mobile@3x.png'
import { breakpoints, tablet } from 'shared/media-queries'

const getWordingWidth = screenWidth => {
	if (screenWidth <= breakpoints.mobile) {
		return '600px'
	} else if (screenWidth <= breakpoints.laptop) {
		return '460px'
	}
	return '580px'
}

const KeepTabs = ({ screenWidth }: { screenWidth: number }) => (
	<InfoAndImage
		screenWidth={screenWidth}
		showNewBadge
		imageOnRight
		image={
			screenWidth < breakpoints.tablet ? (
				<Img
					src={m2Mobile}
					srcSet={`
					${m2Mobile2x} 2x,
					${m2Mobile3x} 3x,
				`}
					css={{
						[tablet]: { height: 'auto' },
					}}
				/>
			) : (
				<Img
					src={m2}
					srcSet={`
					${m22x} 2x,
					${m23x} 3x,
				`}
					css={{
						[tablet]: { height: 'auto', width: '90%' },
					}}
				/>
			)
		}
		subtitle="Keep tabs on your entire business."
		info="Save and share detailed reports with your team that automatically update when new data comes in. Drill down and instantly see the information you want."
		centered={screenWidth <= breakpoints.tablet}
		wordingWidth={getWordingWidth(screenWidth)}
		spacerWidths={['120px', '20px']}
		bulletItems={[
			'Jobs you’ve been invited to and all relevant bid info',
			'What’s next in your active jobs for the next two weeks',
			'The bids you didn’t win so you can figure out why',
		]}
	/>
)

export default KeepTabs
