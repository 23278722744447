// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { tablet, mobile } from 'shared/media-queries'
import { gray80 } from 'shared/colors'
import Spacer from 'components/spacer'
import H2 from 'components/h2'

const Heading = () => (
	<Div
		display="flex"
		flexDirection="column"
		alignItems="center"
		margin="0 auto"
	>
		<Spacer height="20px" />
		<H2
			textAlign="center"
			maxWidth="650px"
			additionalCss={{
				[tablet]: { fontSize: '36px', maxWidth: '600px' },
				[mobile]: { fontSize: '32px', maxWidth: '266px' },
			}}
		>
			See essential GC information whenever you need it.
		</H2>
		<Spacer height="20px" />
		<Div
			fontSize="24px"
			lineHeight="1.33"
			maxWidth="620px"
			textAlign="center"
			color={gray80}
			css={{
				[tablet]: { fontSize: '22px', maxWidth: '510px' },
				[mobile]: { fontSize: '20px', lineHeight: '1.25', maxWidth: '312px' },
			}}
		>
			Learn which GCs you’re winning the most work with, so you know where to
			focus your time.
		</Div>
	</Div>
)

export default Heading
