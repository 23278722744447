// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'

import { InfoAndImage } from 'views/components'
import m4 from './img/m4.png'
import m42x from './img/m4@2x.png'
import m43x from './img/m4@3x.png'
import m4Mobile from './img/m4-mobile.png'
import m4Mobile2x from './img/m4-mobile@2x.png'
import m4Mobile3x from './img/m4-mobile@3x.png'
import { breakpoints, tablet } from 'shared/media-queries'

const getWordingWidth = screenWidth => {
	if (screenWidth <= breakpoints.mobile) {
		return '431px'
	} else if (screenWidth <= breakpoints.tablet) {
		return '635px'
	} else if (screenWidth <= breakpoints.laptop) {
		return '460px'
	}

	return '485px'
}

const GetSnapshot = ({ screenWidth }: { screenWidth: number }) => (
	<InfoAndImage
		imageOnRight
		image={
			screenWidth < breakpoints.tablet ? (
				<Img
					width="100%"
					src={m4Mobile}
					srcSet={`
					${m4Mobile2x} 2x,
					${m4Mobile3x} 3x,
				`}
					css={{
						[tablet]: { height: 'auto' },
					}}
				/>
			) : (
				<Img
					width="100%"
					src={m4}
					srcSet={`
					${m42x} 2x,
					${m43x} 3x,
				`}
					css={{
						[tablet]: { height: 'auto', width: '75%' },
					}}
				/>
			)
		}
		subtitle={
			<Div maxWidth={getWordingWidth(screenWidth)}>
				Get a snapshot of your entire business, whenever you need it.
			</Div>
		}
		info="See important stats in an easy dashboard view. Bid Board Pro
		automatically updates your dashboard, so you know your numbers all the
		time—no calculations needed."
		wordingWidth={getWordingWidth(screenWidth)}
		centered={screenWidth <= breakpoints.tablet}
		spacerWidths={['120px', '20px']}
	/>
)

export default GetSnapshot
