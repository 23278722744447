// @flow
import * as React from 'react'
import { Div, A, Img } from 'glamorous'

import { ResourceHub } from 'views/components'
import BlogImage from './img/blog.png'
import BlogImage2x from './img/blog@2x.png'
import BlogImage3x from './img/blog@3x.png'

import videoImage from './img/videoImage.png'
import videoImage2x from './img/videoImage@2x.png'
import videoImage3x from './img/videoImage@3x.png'
import { navy } from 'shared/colors'

const cards = [
	{
		image: (
			<Img
				width="100%"
				src={videoImage}
				srcSet={`${videoImage2x} 2x, ${videoImage3x} 3x`}
			/>
		),
		title: 'Video',
		wording: 'How to leverage your bid history.',
		cta: 'Watch the video',
		videoId: '5njlssbfl8',
	},
	{
		image: (
			<Img
				width="100%"
				src={BlogImage}
				srcSet={`${BlogImage2x} 2x, ${BlogImage3x} 3x`}
			/>
		),
		title: 'Blog',
		wording: '3 ways subcontractors can get the most out of their data',
		cta: 'Read more',
		href: `https://blog.buildingconnected.com/3-ways-subcontractors-can-get-the-most-out-of-their-data/`,
	},
	{
		title: 'How To',
		wording: (
			<Div>
				<A
					color={navy}
					href="https://buildingconnected.zendesk.com/hc/en-us/articles/360009722734-Understanding-your-company-s-analytics-in-BuildingConnected-Bid-Board-Pro-"
					target="_blank"
				>
					How to understand your company’s analytics.
				</A>
				<br />
				<br />
				<A
					color={navy}
					href="https://buildingconnected.zendesk.com/hc/en-us/articles/360019203553-Leaderboard-FAQ-s-Bid-Board-Pro-"
					target="_blank"
				>
					How to use the BuildingConnected leaderboard.
				</A>
				<br />
				<br />

				<A
					color={navy}
					href="https://buildingconnected.zendesk.com/hc/en-us/articles/360013530533-How-to-view-your-Reports-page-and-create-custom-reports-Bid-Board-Pro-%E2%80%9D"
					target="_blank"
				>
					How to create custom reports.
				</A>
			</Div>
		),
	},
]

const ResourceHubSection = () => (
	<ResourceHub
		title="The resource hub."
		subtitle="Get all the information you need to take your business to the next level by bidding smarter."
		cards={cards}
	/>
)

export default ResourceHubSection
