// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { grayText } from 'shared/colors'
import { tablet, mobile } from 'shared/media-queries'

type SectionType = {
	icon: React.Node,
	description: string,
}
const Section = ({ icon, description }: SectionType): React.Node => (
	<Div
		display="flex"
		marginTop="45px"
		alignItems="center"
		height="75px"
		css={{
			[mobile]: {
				textAlign: 'center',
				flexDirection: 'column',
				height: 'auto',
			},
		}}
	>
		<Div
			width="75px"
			alignItems="center"
			justifyContent="center"
			marginRight="30px"
			css={{
				[tablet]: { marginRight: '55px' },
				[mobile]: { marginRight: '0px', marginBottom: '10px' },
			}}
		>
			{icon}
		</Div>
		<Div
			fontSize="18px"
			color={grayText}
			maxWidth="300px"
			css={{ [tablet]: { maxWidth: '380px' }, [mobile]: { maxWidth: '261px' } }}
		>
			{description}
		</Div>
	</Div>
)

const InfoGrid = ({ sections }: { sections: Array<SectionType> }) => (
	<Div
		display="flex"
		flexWrap="wrap"
		justifyContent="center"
		marginTop="-45px"
		css={{
			[tablet]: { flexDirection: 'column', alignItems: 'center' },
		}}
	>
		{sections.map(section => (
			<Div key={section.description} flex="0 0 42%" alignItems="center">
				<Section {...section} key={section.description} />
			</Div>
		))}
	</Div>
)

export default InfoGrid
