// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { Hero } from 'views/components'
import H1 from 'components/h1'
import { breakpoints, tablet, mobile } from 'shared/media-queries'
import HeaderBackgroundImage from './background-image'

import { cyan } from 'shared/colors'

// eslint-disable-next-line
const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.desktop) {
		return '486px'
	} else if (screenWidth >= breakpoints.laptop) {
		return '613px'
	} else if (screenWidth > breakpoints.mobile) {
		return '644px'
	}
	return '262px'
}

const heading = (
	<H1
		additionalCss={{
			[tablet]: { fontSize: '51px' },
			[mobile]: { fontSize: '32px' },
		}}
	>
		Get the answers you need to win the work you want.
	</H1>
)

const subHeading = (
	<Div
		fontSize="24px"
		lineHeight="1.33"
		css={{
			[mobile]: { fontSize: '18px' },
		}}
	>
		Check win rates, track team performance, and see how your business is doing
		with Bid Board Pro’s suite of analytics tools.
	</Div>
)

const Header = ({ screenWidth }: { screenWidth: number }) => (
	<Hero
		backgroundImage={<HeaderBackgroundImage screenWidth={screenWidth} />}
		heading={heading}
		subHeading={subHeading}
		wordingWidth={getWordingWidth(screenWidth)}
		ctaColor={cyan}
	/>
)

export default Header
